import { types, flow } from 'mobx-state-tree';
import { Tap } from './types';
import api from 'services/API';

export const tapsInitialState = {
  all: [],
  isLoaded: false,
};

export const tapsModel = types
  .model({
    all: types.array(Tap),
    isLoaded: types.boolean,
  })
  .views(self => ({
    get taps() {
      return self.all;
    },
  }))
  .actions(self => {
    return {
      setTaps(taps) {
        self.all.replace(taps);
        self.isLoaded = true;
      },
      getTapIdByIdentifier(identifier) {
        return self.all.find(t => t.identifier === `Tap-${identifier}`)?.id;
      },
      patchTap: flow(function* (id, body) {
        try {
          const response = yield api.patchTap(id, body);

          if (response?.data?.row) {
            return self.updateTap(response.data.row);
          }
        } catch (err) {
          return Promise.reject(err);
        }
      }),
      updateTap(_tap) {
        const index = self.all.findIndex(tap => tap.id === _tap.id);

        if (index >= 0) {
          Object.assign(self.all[index], {
            ..._tap,
          });
        }
      },
    };
  });
